.img_logo {
  object-fit: cover;
  width: auto;
  height: 50px;
  @media only screen and (max-width: 1089px) {
    width: auto;
    height: 50px;
  }
}
.logo_en {
  margin-left: -35px;
}
.logo_ar {
  margin-right: -35px;
}
