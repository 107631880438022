@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');

.produced-by-container {
    margin-top: 2rem;
    background: #f4efff;
    width: 100%;
    padding: 10px 41px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.produced-by-container .phone-link {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.produced-by-container .phone-link p {
    color: #000;
    font-family: "Cairo", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.produced-by-container .phone-link .callIconLink {
    display: flex;
    align-items: center;
    gap: 4px;
    direction: ltr;
}

.produced-by-container .phone-link .callIconLink img {
    height: 18px;
    width: 18px;
}

.produced-by-container .phone-link .callIconLink.whastapp-con img {
    height: 20px;
    width: 20px;
}

.produced-by-container .phone-link .callIconLink span {
    color: #000;
    font-family: "Cairo", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.produced-by-container .web-link .websiteIconLink {
    display: flex;
    align-items: center;
    gap: 8px;
}

.produced-by-container .web-link .websiteIconLink img {
    height: 18px;
    width: 18px;
}

.produced-by-container .web-link .websiteIconLink span {
    color: #000;
    font-family: "Cairo", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

@media only screen and (max-width: 1589px) {
    .produced-by-container {
        padding: 16px 40px;
    }
}

@media only screen and (max-width: 1091px) {
    .produced-by-container {
        padding: 16px 30px;
    }
}

@media only screen and (max-width: 900px) {
    .produced-by-container {
        padding: 16px 30px;
        margin-top: 4rem;
    }
}

@media only screen and (max-width: 700px) {
    .produced-by-container {
        padding: 8px 18px;
        margin-top: 4rem;
    }

    .produced-by-container .phone-link p {
        font-size: 10px;
    }

    .produced-by-container .phone-link .callIconLink img {
        height: 12px;
        width: 12px;
    }

    .produced-by-container .phone-link .callIconLink span {
        font-size: 10px;
    }

    .produced-by-container .web-link .websiteIconLink img {
        height: 12px;
        width: 12px;
    }

    .produced-by-container .web-link .websiteIconLink span {
        font-size: 10px;
    }
}